const PRODUCTION = 'production';
const DEVELOPMENT = 'development';
const PRESTAGING = 'prestaging';
const STAGING = 'staging';

const getEnvironment = () => {
  if (process.env && process.env.APP_ENV) {
    return process.env.REACT_APP_MODE || process.env.NODE_ENV || DEVELOPMENT;
  }
  return undefined;
};

const environment = getEnvironment();

const getUri = () => {
  if (process.env && process.env.APP_ENV) {
    return process.env.APP_ENV;
  }
  return window.location.hostname;
};

const uri = getUri();
// const uri = window.location.hostname;

export const getCraftEndpoint = () =>
  uri === 'localhost'
    ? 'http://localhost:8001/v1/secured/'
    : 'https://ad-preview.advertising.aol.com/v1/secured/';

export const getAPIEndpoint = (currentEnv, currentURL) => {
  let env = currentEnv || environment;
  const url = currentURL || uri;
  // const { APP_CLUSTER } = process.env;
  let API_URL = 'https://actservices.yahooinc.com/';

  // When deploying with SD, the app is built only once by the component job.
  // This makes process.env.NODE_ENV the same for component, staging and production jobs.
  // In this case we can rely on hostname to detect the real environment to run
  if (env === PRODUCTION) {
    if (url.includes('development')) {
      // actapp.development.omega.bf1.yahoo.com
      env = DEVELOPMENT;
    } else if (url.includes('staging')) {
      // actapp.staging.omega.gq1.yahoo.com:
      env = STAGING;
    } else {
      env = PRODUCTION;
    }
  }

  switch (env) {
    case DEVELOPMENT:
      // return 'https://actapi-api-staging.v2-prod1-gq1.omega.corp.yahoo.com/';
      API_URL =
        'https://actapi-api-development.v2-prod1-gq1.omega.corp.yahoo.com/';
      // console.log('SWITCH ME BACK TO DEV AT SOME POINT BEFORE MERGE');
      // API_URL = 'https://actservices.yahooinc.com/';
      // API_URL = 'https://actapi.corp.yahoo.com/';
      break;
    case PRESTAGING:
      API_URL = 'https://actapi-api-staging.v2-prod1-gq1.omega.corp.yahoo.com/';
      break;
    case STAGING:
      API_URL = 'https://actapi.corp.yahoo.com/';
      break;
    default:
      // production and etc.
      API_URL = 'https://actservices.yahooinc.com/';
      break;
  }
  /* switch endpoint based on deployment environment
  switch (env) {
    case DEVELOPMENT:
      API_URL =
        APP_CLUSTER === 'omega-aws' || url.includes('sharedtech-nonprod1')
          ? 'https://actapi-api-development.sharedtech-nonprod1-use1.omega.aws.yahoo.cloud/'
          : 'https://actapi-api-development.v2-prod1-gq1.omega.corp.yahoo.com/';
      break;
    case PRESTAGING:
      API_URL = 'https://actapi-api-staging.v2-prod1-gq1.omega.corp.yahoo.com/';
      break;
    case STAGING:
      API_URL = 'https://actapi.corp.yahoo.com/';
      break;
    default:
      // this is production
      if (APP_CLUSTER === 'omega-aws' || url.includes('sharedtech-prod1')) {
        API_URL =
          'https://actapi-api-production.sharedtech-prod1-use1.omega.aws.yahoo.cloud/';
      } else {
        API_URL = 'https://actservices.yahooinc.com/';
      }
      break;
  }
  */
  // Simply re-set to whatever you want if you need to do some local testing / running.
  // API_URL = 'http://localhost:4000/';
  // API_URL = 'https://actservices.yahooinc.com/';
  // API_URL = 'https://actapi-api-development.v2-prod1-gq1.omega.corp.yahoo.com/';

  return API_URL;
};
